.button-n4m {
    /* width: 85px; */
    height: 30px;
    line-height: 17px !important;
}
.tag-close {
    cursor: pointer;
}
thead, tbody, tfoot, tr, td, th {
    vertical-align: baseline;
}
td.tags {
    width: 60%;
    vertical-align: middle;
}
td.categories {
    vertical-align: middle;
}
td.actions {
    vertical-align: middle;
}
div.tag {
    margin: 1px;
    background: #673ab7;
}
div.tag span.tag-name {
    color: #FFF;
}
.add-tag-button {
    border: 1px solid #ddd;
}
.add-tag-button:hover {
    border: 1px solid #ddd;
}
.collapsible {
    background-color: #777;
    color: white;
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    font-size: 15px;
    border-radius: 25px;
}

.pagination-grid .active, .collapsible:hover {
    //background-color: #555;
    //--bs-pagination-active-bg: #0dfd54;
    //--bs-pagination-active-border-color: auto;
}

.content {
    padding: 0 13px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}
.pagination-grid {
    --bs-pagination-bg : beige;
    --bs-pagination-disabled-bg: #ddd;
}
